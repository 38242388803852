import {scroll} from "motion";
import Swiper from "swiper";
import {Autoplay, EffectFade, Pagination} from "swiper/modules";
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import 'fullpage.js/dist/fullpage.min.css';

const items = document.querySelectorAll(".c-horizontal__list-item");

const horizontalMenuItems = document.querySelectorAll(".js-horizontal__menu-item");

const growthElems = document.querySelectorAll('.js-growth');

// Animate gallery horizontally during vertical scroll

window.mobileCheck = function () {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

// Intersection Observer setup
const observerOptions = {
    root: null, // Use the viewport as the container
    rootMargin: "0px", threshold: 0.5 // Trigger when 50% of the item is visible
};
const getIndex = (element) => Array.prototype.indexOf.call(element.parentNode.children, element);

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const index = getIndex(entry.target);
            horizontalMenuItems.forEach((menuItem, menuIndex) => {

                let newIndex = index;
                if (mobileCheck()) {
                    newIndex--;
                }

                horizontalMenuListElem.dataset.index = newIndex;
                if (menuIndex === newIndex) {
                    menuItem.classList.add("is-active");
                } else {
                    menuItem.classList.remove("is-active");
                }
            });
        }
    });
}, observerOptions);

horizontalMenuItems.forEach((item) => {
    item.addEventListener('click', (element) => {
        const index = element.target.dataset.href;
        if (!mobileCheck()) {
            fullpage_api.moveTo('unique-spaces', index);
        } else {
            const elem = document.querySelectorAll('.c-horizontal__list-item')[index];
            const offset = getOffset(elem);
            scrollToPosition(0, offset.top - 100);
        }

    });
});

items.forEach(item => observer.observe(item));

const headerElem = document.querySelector('.js-header');
const horizontalMenuListElem = document.querySelector('.js-horizontal__menu-list');
const container = document.querySelector('.c-before-after');
const newImage = document.querySelector('.c-before-after__image_new');
const slider = document.querySelector('.c-before-after__handle');
let isDragging = false;

// Helper function to handle both mouse and touch events
function updateSlider(event) {
    let clientX;

    if (event.type.startsWith('touch')) {
        clientX = event.touches[0].clientX;
    } else {
        clientX = event.clientX;
    }

    const containerRect = container.getBoundingClientRect();
    const offsetX = clientX - containerRect.left;
    const offsetXPercent = (offsetX / containerRect.width) * 100;

    if (offsetXPercent >= 0 && offsetXPercent <= 100) {
        newImage.style.cssText = `clip-path:polygon(0 0, ${offsetX}px 0, ${offsetX}px 100%, 0 100%) !important`;
        slider.style.left = `${offsetXPercent}%`;
    }
}

container.addEventListener('mousedown', (e) => {
    isDragging = true;
    updateSlider(e);
});
document.querySelector('.c-menu-bt').addEventListener('click', (e) => {
    document.querySelector('.c-menu-bt').classList.toggle('is-active');
    document.querySelector('.c-menu').classList.toggle('is-active');
});

container.addEventListener('touchstart', (e) => {
    isDragging = true;
    updateSlider(e);
});

document.addEventListener('mouseup', () => {
    isDragging = false;
});

document.addEventListener('touchend', () => {
    isDragging = false;
});

document.addEventListener('mousemove', (e) => {
    if (isDragging) {
        updateSlider(e);
    }
});

document.addEventListener('touchmove', (e) => {
    if (isDragging) {
        updateSlider(e);
    }
});


document.querySelectorAll('.c-menu__list-item').forEach(item => {
    item.addEventListener('click', (e) => {
        document.querySelector('.c-menu-bt').classList.remove('is-active');
        document.querySelector('.c-menu').classList.remove('is-active');

        if (mobileCheck()) {
            const anchor = e.target.href.split('#')[1];
            const elem = document.querySelector(`[data-anchor="${anchor}"]`);
            const offset = getOffset(elem);
            console.log(offset, anchor, elem);
            scrollToPosition(0, offset.top - 50)
        }
    });
});


function isInMiddleViewport(elem) {
    const rect = elem.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Calculate the midpoint of the viewport
    const viewportMidpoint = windowHeight / 2;

    // Check if the element has reached the halfway point of the screen
    return (
        rect.top <= viewportMidpoint &&
        rect.bottom >= viewportMidpoint
    );
}

const menuItems = document.querySelectorAll('.c-menu__list-item-link');
const sections = Array.from(menuItems).map(item => {
    const id = item.getAttribute('href').slice(1); // Get section ID from href
    return document.querySelector(`[data-anchor="${id}"]`); // Find corresponding section by ID
});

// Function to check if an element is in the viewport
function isInViewport(elem) {
    const rect = elem.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Check if any part of the element is visible
    return (
        rect.top <= windowHeight &&
        rect.bottom >= 100
    );
}

// Function to update active menu item based on scroll position
function updateActiveMenuItem() {
    let foundActive = false;

    sections.forEach((section, index) => {
        const menuItem = menuItems[index].parentNode; // Parent li element
        if (isInViewport(section)) {
            // Only add 'is-active' to the first found section in view
            if (!foundActive) {
                menuItem.classList.add('is-active');
                foundActive = true;
            } else {
                menuItem.classList.remove('is-active');
            }
        } else {
            menuItem.classList.remove('is-active');
        }
    });

    // If no section is in view, optionally set default active menu item
    if (!foundActive) {
        // e.g., menuItems[0].parentNode.classList.add('is-active');
    }
}

if (mobileCheck()) {
    updateActiveMenuItem();
}

scroll(({y}) => {
    if (y.current < 100) {
        headerElem.classList.remove('is-visible');
    } else {
        headerElem.classList.add('is-visible');
    }
    if (mobileCheck()) {
        updateActiveMenuItem();
        if (isInMiddleViewport(growthElems[0]) || isInMiddleViewport(growthElems[1])) {
            document.querySelector("body").classList.add('is-red');
        } else {
            document.querySelector("body").classList.remove('is-red');
        }
    }

}, {
    target: document.querySelector("body")
});

function getOffset(element) {
    const rect = element.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop, left: rect.left + scrollLeft
    };
}

function scrollToPosition(x, y) {
    window.scrollTo({
        top: y, left: x, behavior: 'smooth' // Optional: 'auto' or 'smooth'
    });
}

const swiper = new Swiper('.js-experts-swiper', {
    modules: [EffectFade, Pagination, Autoplay], speed: 800, loop: true, autoplay: {
        delay: 4000
    }, pagination: {
        el: ".swiper-pagination", clickable: true,
    }, effect: "fade", fadeEffect: {

        crossFade: true
    },
});
if (!mobileCheck()) {
    new fullpage('#fullpage', {
        // licenseKey: '1234-5678-90AB-CDEF-12345678901234567890123456789',
        licenseKey: 'bleh',
        scrollHorizontally: true,
        scrollHorizontallyKey: 'UzBkR1ZzWldkeVlXWjNZWEp6ZW1GM1lTNXdiQT09T1dfQXJNYzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1UVdX',
        anchors: ['intro', 'about', 'unique-spaces', 'building', 'building2', 'investor', 'contact'],
        menu: '#fp-menu',
        onLeave: function (section, origin, destination, direction) {
            console.log({
                section: section, origin: origin, destination: destination, direction: direction, index: section.index
            });
            origin.item.classList.add('is-in-view-first', 'is-in-view');
            section.item.classList.remove('is-in-view');

            if (origin.index !== 0) {
                headerElem.classList.add('is-visible');
            } else {
                headerElem.classList.remove('is-visible');
            }

            switch (origin.anchor) {
                case 'building':
                case 'building2':
                    document.querySelector("body").classList.add('is-red');
                    break;
                default:
                    document.querySelector("body").classList.remove('is-red');
                    break;
            }

        },
        afterSlideLoad: function (section, origin, destination, direction) {
            console.log({
                section: section, origin: origin, destination: destination, direction: direction
            });
            destination.item.classList.add('is-in-view-first', 'is-in-view');
            origin.item.classList.remove('is-in-view');
        },
        onSlideLeave: function (section, origin, destination, direction) {
            console.log({
                section: section, origin: origin, destination: destination, direction: direction
            });
            horizontalMenuListElem.dataset.index = destination.index;
        }
    });
}
setInterval(function () {
    var div = document.querySelector('div[style*="z-index:9999999"]');
    if (div) {
        div.style.display = 'none';
    }
}, 50);
